
.header[data-v-1f234af1] {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    font-size: 22px;
    color: #fff;
}
.collapse-btn[data-v-1f234af1] {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 70px;
}
.header .logo[data-v-1f234af1] {
    float: left;
    width: 250px;
    line-height: 70px;
}
.header-right[data-v-1f234af1] {
    float: right;
    padding-right: 50px;
}
.header-user-con[data-v-1f234af1] {
    display: flex;
    height: 70px;
    align-items: center;
}
.btn-fullscreen[data-v-1f234af1] {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}
.btn-bell[data-v-1f234af1],
.btn-fullscreen[data-v-1f234af1] {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.btn-bell-badge[data-v-1f234af1] {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell[data-v-1f234af1] {
    color: #fff;
}
.user-name[data-v-1f234af1] {
    margin-left: 10px;
}
.user-avator[data-v-1f234af1] {
    margin-left: 20px;
}
.user-avator img[data-v-1f234af1] {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.el-dropdown-link[data-v-1f234af1] {
    color: #fff;
    cursor: pointer;
}
.el-dropdown-menu__item[data-v-1f234af1] {
    text-align: center;
}
.psdText[data-v-1f234af1] {
    font-size: 14px;
    margin-right: 10px;
}
.line[data-v-1f234af1] {
    display: inline-block;
    width: 48px;
    height: 4px;
    background: #d8d8d8;
    border-radius: 3px;
    margin-right: 8px;
}
.low[data-v-1f234af1] {
    background: #f4664a;
}
.middle[data-v-1f234af1] {
   background: #ffb700;
}
.high[data-v-1f234af1] {
    background: #2cbb79;
}
.level[data-v-1f234af1] {
    margin: 0 16px 0 8px;
}
.warningtext[data-v-1f234af1] {
    color: #5a5a5a;
    font-size: 12px;
    margin-top: 5px;
}

