
.sidebar[data-v-68d67528] {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar[data-v-68d67528]::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu[data-v-68d67528]:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul[data-v-68d67528] {
    height: 100%;
}
